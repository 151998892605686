<script lang="ts">
  import Base from '@factry/base'
  import EquipmentManager from '@factry/equipment-manager'
  import MaterialManager from '@factry/material-manager'
  import MessageQueueManager from '@factry/message-queue-manager'
  import InstructionManager from '@factry/instruction-manager'
  import OrderManager from '@factry/order-manager'
  import WasteManager from '@factry/waste-manager'
  import OperationsManager from '@mtc/operations-manager'
</script>

<Base
  options={[
    OperationsManager, EquipmentManager, MaterialManager, MessageQueueManager,InstructionManager, OrderManager, WasteManager
  ]}
/>
