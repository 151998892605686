
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma/scss/bulma.scss'
import * as Sentry from '@factry/sentry'
import { initialize } from '@factry/base'
import settings from '@factry/translation-manager/src/settings'

import App from './App.svelte'
import background from './assets/background.jpg'
import logo from './assets/MTC-logo.svg'

initialize({
  availableLocales: ['sv-SE', 'en'],
  background,
  basePath: import.meta.env.VITE_BASE_PATH,
  defaultLocale: 'en',
  logo,
  logoAlt: 'MTC',
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
  organization: 'mtc',
})

Sentry.init({
  version: import.meta.env.VITE_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  proxy: import.meta.env.VITE_BASE_PATH,
})

settings.canEditDefaultLocale = true

const app = new App({
  target: document.body,
})

export default app
